'use client'

import { ListingCardRedesignBase } from '../../../../../features/listing-card/listing-card-redesign'
import type { ListingCard_ListingFragment } from '../../../../../features/listing-card/__generated__/listing-card.gql'
import styles from './nearby-properties-listing-card.module.css'

type NearbyPropertiesListingCardProps = {
  listing: ListingCard_ListingFragment
  hasTodaysTopPick: boolean
  isMobile: boolean
}

export function NearbyPropertiesListingCard({
  listing,
  hasTodaysTopPick,
  isMobile,
}: NearbyPropertiesListingCardProps) {
  return (
    <div className={styles.container}>
      <ListingCardRedesignBase
        listing={listing}
        simplifiedCard={true}
        hasTodaysTopPick={hasTodaysTopPick}
        isMobile={isMobile}
        updatePlays={() => {
          // This is not used with simplified cards, since there is just one image
        }}
      />
    </div>
  )
}
