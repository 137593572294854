import { useMemo } from 'react'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { CTAButtons } from '../../../../features/cta-buttons/cta-buttons'
import { CHECK_AVAILABILITY } from '../../../../features/cta-buttons/cta-buttons.const'
import type { ListingCardCTAProps } from '../../../../features/listing-card/listing-card.types'
import { useHideRequestTour } from '../../../../features/detail/use-hide-request-tour'
import styles from './listing-card-cta.module.css'

export function ListingCardCTA(props: ListingCardCTAProps) {
  const withUpdatedStyles =
    useFeatureVariable<number>(['listing_card_redesign', 'version'], 0) === 2

  const hideRequestTour = useHideRequestTour(
    props.listing.availabilityStatus,
    props.listing.tourProviderDetails,
    props.listing.requestTourOptOut
  )

  const listingCardCTAs = useMemo(() => {
    return hideRequestTour ? (
      <>
        <CTAButtons.PhoneCTA fluid variant="outlined" />
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          fluid
          variant="primary"
          hideIcon
          label={CHECK_AVAILABILITY}
        />
      </>
    ) : (
      <>
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          fluid
          variant="outlined"
          hideIcon
          label={CHECK_AVAILABILITY}
        />
        <CTAButtons.TourCTA
          data-tid="cta-schedule-a-tour"
          fluid
          variant="primary"
        />
      </>
    )
  }, [hideRequestTour])

  const listingCardCTAsWithUpdatedStyles = useMemo(() => {
    return hideRequestTour ? (
      <>
        <CTAButtons.PhoneCTA
          variant="agSecondaryRedesign"
          className={styles.withUpdatedStyles}
          hideIcon
        />
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          variant="primary"
          label={CHECK_AVAILABILITY}
          fluid
          hideIcon
          className={styles.withUpdatedStyles}
        />
      </>
    ) : (
      <>
        <CTAButtons.EmailCTA
          data-tid="cta-email-property-lead"
          variant="agSecondaryRedesign"
          label={CHECK_AVAILABILITY}
          hideIcon
          className={styles.withUpdatedStyles}
        />
        <CTAButtons.TourCTA
          data-tid="cta-schedule-a-tour"
          fluid
          variant="primary"
          className={styles.withUpdatedStyles}
          withAlternateAgIcon
        />
      </>
    )
  }, [hideRequestTour])

  return (
    <CTAButtons
      data-tid="cta-buttons"
      isMobile={props.isMobile}
      className={props.ctaClass}
      listing={props.listing}
      currentRefinementSlugs={props.currentRefinementSlugs}
      locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
    >
      {withUpdatedStyles ? listingCardCTAsWithUpdatedStyles : listingCardCTAs}
    </CTAButtons>
  )
}
