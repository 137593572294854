import { ReactComponent as ShareArrowIcon } from '@brand/icons/share-arrow.svg'
import clsx from 'clsx'
import styles from './icon-ctas.module.css'
import type { MouseEventHandler } from 'react'
import { Button } from '../button/button'

interface ShareButtonProps {
  'data-tag_selection'?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  listingCardRedesignVersion?: number
}

export function ShareButton({
  onClick,
  listingCardRedesignVersion,
  ...props
}: ShareButtonProps) {
  const isRedesignV2 = listingCardRedesignVersion === 2
  const className = isRedesignV2
    ? styles.shareButtonRedesignV2 // AG Redesign V2 - use "tertiary-redesign" button with some modifications
    : clsx(styles.iconCtaStyle, styles.default, {
        [styles.shareButtonRedesignV1]: listingCardRedesignVersion === 1,
      })

  const Component = isRedesignV2 ? Button : 'button'

  return (
    <Component
      data-tag_item="share_button"
      data-tag_selection={props['data-tag_selection']}
      data-tid="share"
      className={className}
      aria-label="share"
      onClick={onClick}
      variant={isRedesignV2 ? 'agSecondaryRedesign' : undefined}
      rounded={isRedesignV2 ? true : undefined}
    >
      <ShareArrowIcon aria-hidden />
    </Component>
  )
}
