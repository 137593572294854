import styles from './map-modal.module.css'
import { StickyFooter } from '../detail/sticky-footer/sticky-footer'
import type { CtaButtons_ListingFragment } from '../cta-buttons/__generated__/cta-buttons.gql'
import type { Address_ListingFragment } from '../detail/address-map/__generated__/address-map.gql'
import { ListingLocationMap } from './listing-location-map'
import { useCallback, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { DialogModalCloseButton } from '../dialog-modal/dialog-modal-close-button'
import { DialogModal } from '../dialog-modal/dialog-modal'
import LazyHydrate from 'react-lazy-hydration'

type MapModalProps = {
  listing: CtaButtons_ListingFragment & Address_ListingFragment
  zoom?: number
  heading?: string | null
  subHeading?: string | null
  showControls?: boolean
  onAfterClose: () => void
  open: boolean
}

export function MapModal({
  listing,
  zoom,
  subHeading,
  heading,
  onAfterClose,
  open,
  showControls,
}: MapModalProps) {
  const ref = useRef<HTMLDialogElement>(null)
  const center = useMemo<google.maps.LatLngLiteral>(
    () => ({
      lng: listing.location?.lng as number,
      lat: listing.location?.lat as number,
    }),
    [listing.location?.lng, listing.location?.lat]
  )

  const handleCloseMapModal = useCallback(function handleCloseMapModal() {
    ref.current?.close()
  }, [])

  return (
    <LazyHydrate whenIdle>
      <DialogModal
        id="map-modal"
        ref={ref}
        open={open}
        entranceAnimation="slide-from-bottom"
        className={clsx(
          styles.modal,
          listing.isBasic
            ? styles.basicListingMapTogglePosition
            : styles.notBasicListingMapTogglePosition
        )}
        data-tag_section="map_modal"
        onAfterClose={onAfterClose}
      >
        <div className={styles.mapContainer}>
          <div className={styles.mapHeaderWrapper}>
            <div className={styles.mapHeaderContent}>
              <div className={styles.mapHeaderText}>
                {heading && <span className={styles.heading}>{heading}</span>}
                <span className={styles.subheading}>
                  {subHeading || listing.addressFull}
                </span>
              </div>

              <DialogModalCloseButton
                data-tid="modal-close-button"
                data-tag_item="close_map_modal"
                data-tag_section="map_modal"
                onClick={handleCloseMapModal}
              />
            </div>
          </div>
          <div className={styles.map}>
            {open && (
              <ListingLocationMap
                showToggle
                showControls={showControls}
                center={center}
                zoom={zoom}
              />
            )}
          </div>
          {!listing.isBasic && (
            <div className={styles.mapModalFooter}>
              <StickyFooter
                listing={listing}
                isVisible
                onShowModal={handleCloseMapModal}
              />
            </div>
          )}
        </div>
      </DialogModal>
    </LazyHydrate>
  )
}
