import type { ListingCard_ListingFragment } from '../__generated__/listing-card.gql'
import styles from './bed-count-data.module.css'
import { useMemo } from 'react'
import { getFilterMatchResultPriceSqFtDisplayValues } from './utils'
import clsx from 'clsx'
import type { FilterMatchResults_Fragment } from '../../search/search-results/__generated__/search-results.gql'

type BedCountDataProps = {
  filterMatchResult?: FilterMatchResults_Fragment | null
  listing: ListingCard_ListingFragment
  withUpdatedStyles?: boolean
}

export function BedCountData({
  listing,
  filterMatchResult,
  withUpdatedStyles,
}: BedCountDataProps) {
  const displayValuesByBed: {
    sqFtText?: string
    priceText?: string
    bedText?: string
  }[] = useMemo(
    () =>
      getFilterMatchResultPriceSqFtDisplayValues({
        bedCountData: filterMatchResult?.bedCountData,
      }),
    [filterMatchResult?.bedCountData]
  )

  return (
    <div
      className={clsx(styles.priceByBedWrapper, {
        [styles.moveInfoToBottom]: listing?.isBasic && !withUpdatedStyles,
        [styles.withUpdatedStyles]: withUpdatedStyles,
      })}
    >
      {displayValuesByBed.length ? (
        displayValuesByBed.map((obj, i) => (
          <div
            data-tid="bed-count-min-price"
            className={styles.priceByBedCount}
            key={`bed-count-cell-${i}`}
          >
            <span data-tid="cell-bed-text">{obj.bedText}</span>
            {obj?.sqFtText && (
              <span data-tid="cell-sqft-text" className={styles.sqFtText}>
                {obj?.sqFtText}
              </span>
            )}

            {obj.priceText && (
              <span data-tid="cell-price-text" className={styles.priceText}>
                {obj.priceText}
              </span>
            )}
          </div>
        ))
      ) : (
        <div
          data-tid="bed-count-contact-for-price"
          className={styles.priceByBedCount}
        >
          <span data-tid="bed-text">
            {displayValuesByBed[0]?.bedText || listing.bedText}
          </span>
          <span data-tid="price-text" className={styles.priceText}>
            Contact for Price
          </span>
        </div>
      )}
    </div>
  )
}
